/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';

import Header, { isEmptyHeader } from '../Header';
import './web.css';
import {
  MAX_CONTENT_WIDTH,
  MAX_TABLET_WIDTH,
  HEADER_SMALL_SCREEN,
  HEADER_LARGE_SCREEN,
  IS_DARK_MODE_KEY,
} from '../../constants';
import { Footer } from '../Footer';

const Container = styled.div`
  margin: 0 auto;
  max-width: ${MAX_CONTENT_WIDTH};
  position: relative;

  [data-id='title'] {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

type MainProps = {
  emptyHeader: boolean;
}
const Main = styled.main<MainProps>`
  padding: 1.5rem 1rem;
  margin-top: ${props => props.emptyHeader ? '3rem' : HEADER_SMALL_SCREEN};
  min-height: 80vh;

  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    padding: 1.5rem 2rem;
    margin-top: ${props => props.emptyHeader ? '3rem' : HEADER_LARGE_SCREEN};
  }
`;

interface Props {
  children: React.ReactNode;
}
const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  if (typeof localStorage === 'undefined' || typeof window === 'undefined' || localStorage == null || !localStorage) {
    return null;
  }
  const isDarkMode = localStorage.getItem(IS_DARK_MODE_KEY);
  // const isDarkMode = true; // LOL Android Error

  const body = document.getElementsByTagName('body')[0];
  if (isDarkMode === 'false') {
    body.setAttribute('data-theme', 'classic');
  } else {
    body.setAttribute('data-theme', 'dark');
  }

  // body.setAttribute('data-theme', 'dark');

  const emptyHeader = isEmptyHeader();

  return (
    <Container>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main emptyHeader={emptyHeader}>{children}</Main>
      {/* <Footer /> */}
    </Container>
  );
};

export default Layout;
